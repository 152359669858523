.calendarWrapper .PrivatePickersFadeTransitionGroup-root {
  color: rgba(68, 68, 68, 1);
}

.calendarWrapper .MuiSvgIcon-root {
  color: rgba(102, 102, 102, 1);
}

.calendarWrapper .MuiPickersArrowSwitcher-root .MuiPickersArrowSwitcher-spacer {
  width: 0;
}

.calendarWrapper
  .MuiCalendarPicker-viewTransitionContainer
  .MuiTypography-caption {
  font-size: 11px;
  color: rgba(85, 85, 85, 1);
}

.calendarWrapper
  .MuiCalendarPicker-viewTransitionContainer
  .PrivatePickersSlideTransition-root
  .MuiPickersDay-root {
  font-size: 14px;
  color: rgba(34, 34, 34, 1);
  letter-spacing: 0.15px;
}

.calendarWrapper
  .MuiCalendarPicker-viewTransitionContainer
  .PrivatePickersSlideTransition-root
  .MuiPickersDay-root.Mui-selected {
  color: #fff;
}
