@media print {
  #root {
    display: none;
  }
  .MuiModal-root {
    display: none;
  }
  #tradereport-close-button,
  #tradereport-print-button,
  #workreport-close-button,
  #workreport-print-button,
  #workreport-button-area {
    display: none;
  }
}
